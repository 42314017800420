<app-navbar-style-two></app-navbar-style-two>
<div class="inner-banner inner-bg1">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Contact Us</h3>
            <ul>
                <li><a href="/">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Contact Us</li>
            </ul>
        </div>
    </div>
</div>
<div class="contact-area">
    <div class="container">
        <div class="contact-max">
            <div class="row">
                <div class="col-lg-3 col-md-3">
                    <div class="contact-card">
                        <i class="flaticon-position"></i>
                        <h3>46-13-28, Near Laxona Baby World Beside Street Gandhi park back side
                        </h3>
                        <h3> Danavai Peta
                            Rajahmundry, Andhra Pradesh 533103
                            India</h3>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3">
                    <div class="contact-card">
                        <i class="flaticon-email"></i>
                        <h3><a href="mailto:beautyschool8@gmail.com">beautyschool8@gmail.com</a></h3>
                        <!-- <h3><a href="mailto:support@pilar.com">Email:support@pilar.com</a></h3> -->
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 offset-md-3 offset-lg-0">
                    <div class="contact-card">
                        <i class="flaticon-to-do-list"></i>
                        <h3><a href="tel:8283849698">8283849698</a></h3>
                        <h3><a href="tel:08832555586">08832555586</a></h3>
                        <!-- <h3><a href="tel:+(05)–25489073901">+(05) – 254 8907 3901</a></h3> -->
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 offset-md-3 offset-lg-0">
                    <div class="contact-card">
                        <i class="flaticon-to-do-list"></i>
                        <h3>Job Notifications</h3>
                        <div>
                            <html>
                            <head>
                                <style>
                                    .blink {
                                        animation: blinker 5.2s linear infinite;
                                        color: rgb(185, 103, 223);
                                        font-family: sans-serif;
                                        text-align: center;
                                        font-size: 20px;
                                    }
                                    @keyframes blinker {
                                        50% {
                                            opacity: 0;
                                        }
                                    }
                                </style>
                            </head>
                            <body>
                                <h3 class="blink" (click)="open(openjobmodel)">Apply For Jasmines</h3>
                            </body>
                            </html>
                        </div>
                        <html>
                        <head>
                            <style>
                                .blink {
                                    animation: blinker 5.2s linear infinite;
                                    color: rgb(185, 103, 223);
                                    font-family: sans-serif;
                                    text-align: center;
                                    font-size: 18px;
                                }
                                @keyframes blinker {
                                    50% {
                                        opacity: 0;
                                    }
                                }
                            </style>
                        </head>
                        <body>
                            <h3 class="blink" (click)="openfor(openmodelforother)">Apply For Franchises</h3>
                        </body>
                        </html>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="contact-map">
    <div class="container-fluid m-0 p-0">
        <!-- <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3815.4843163516516!2d81.7933604906505!3d16.999897777879784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x108115030fac089b!2sSiddhardha%20Children%E2%80%99s%20Hospital!5e0!3m2!1sen!2sin!4v1674018888926!5m2!1sen!2sin"></iframe> -->
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d61044.97704317558!2d81.74722424606833!3d17.00840538432042!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a37a3f4f0daff3f%3A0xe5badff55c4dbb0!2sJASMINES%20CMCC%20%26%20JASMINES%20CMCC%20BE-U-TY%20SCHOOL!5e0!3m2!1sen!2sin!4v1675838831430!5m2!1sen!2sin"
            width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
        <div class="contact-wrap">
            <div class="contact-form">
                <span>SEND MESSAGE</span>
                <h2>Contact With Us</h2>
                <form (ngSubmit)="submitservices()" [formGroup]="serviceForm">
                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <i class='bx bx-user'></i>
                                <input type="text" autocomplete="off" name="name" class="form-control" required
                                    formControlName="name" placeholder="Your Name*">
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <i class='bx bx-user'></i>
                                <input type="email" autocomplete="off" name="email" class="form-control"
                                    formControlName="email" required placeholder="E-mail*"
                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <i class='bx bx-phone'></i>
                                <input type="text" autocomplete="off" name="phone_number" required class="form-control"
                                    formControlName="phone_number" placeholder="Your Phone"
                                    (keypress)="numberOnlyValidation($event)"
                                    onkeyup="this.value = this.value.replace(/[^a-zA-Z0-9]/g, '')" minlength="10"
                                    maxlength="10">
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <i class='bx bx-file'></i>
                                <input type="text" autocomplete="off" name="msg_subject" class="form-control" required
                                    formControlName="msg_subject" placeholder="Your Subject">
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <i class='bx bx-envelope'></i>
                                <textarea name="message" autocomplete="off" class="form-control" cols="30" rows="8"
                                    required formControlName="message" placeholder="Your Message"></textarea>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <button type="submit" class="default-btn border-radius">Send Message <i
                                    class='bx bx-plus'></i></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<ng-template #openjobmodel let-modal>
    <div>
        <div class="modal-header">
            <h5 class="modal-title mt-0 ">Register For Jasmines</h5>
            <button type="button" class="close" aria-hidden="true" (click)="closr()">×</button>
        </div>
        <div class="modal-body">
            <form (ngSubmit)="submitjasminesdata()" [formGroup]="jasminesstaffForm">
                <div class="col-xl-12">
                    <div class="row">
                        <div class="col-xl-12">
                            <label class="form-label">Name</label>
                            <input type="text" class="form-control" formControlName="name" />
                        </div>
                        <div class="col-xl-12">
                            <label class="form-label">Phone Number</label>
                            <input type="text" class="form-control" formControlName="phonenumber"
                                (keypress)="numberOnlyValidation($event)"
                                onkeyup="this.value = this.value.replace(/[^a-zA-Z0-9]/g, '')" minlength="10"
                                maxlength="10" />
                        </div>
                        <div class="col-xl-12">
                            <label class="form-label">Email</label>
                            <input type="text" class="form-control" formControlName="email"
                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" />
                        </div>
                        <div class="col-xl-12">
                            <label for="gender"><b>Gender</b></label><br>
                            <input type="radio" id="Male" name="fav_language" value="Male" formControlName="gender">
                              <label for="Male">Male</label>
                              <input type="radio" id="Female" name="fav_language" value="Female"
                                formControlName="gender">
                              <label for="Female">Female</label><br>
                        </div>
                        <div class="col-xl-12">
                            <label class="form-label">Qualification</label>
                            <input type="text" class="form-control" formControlName="qualification" />
                        </div>
                        <div class="col-xl-12">
                            <label class="form-label">Adress</label>
                            <input type="text" class="form-control" formControlName="reg_address" />
                        </div>
                        <div class="col-xl-12">
                            <label class="form-label"></label>
                        </div>
                        <div class="page-title-right" style="text-align: center ; ">
                            <button class="button-customize"
                                style="background-color: #f175cd;width: 100px;height:40px ">submit</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>
<ng-template #openmodelforother let-modal>
    <div>
        <div class="modal-header">
            <h5 class="modal-title mt-0 ">Register For Others </h5>
            <button type="button" class="close" aria-hidden="true" (click)="closed()">×</button>
        </div>
        <div class="modal-body">
            <form (ngSubmit)="submitotherjasminesdata()" [formGroup]="jasminesotherForm">
                <div class="col-xl-12">
                    <div class="row">
                        <div class="col-xl-12">
                            <label class="form-label">Name</label>
                            <input type="text" class="form-control" formControlName="name" />
                        </div>
                        <div class="col-xl-12">
                            <label class="form-label">Phone Number</label>
                            <input type="text" class="form-control" formControlName="phonenumber"
                                (keypress)="numberOnlyValidation($event)"
                                onkeyup="this.value = this.value.replace(/[^a-zA-Z0-9]/g, '')" minlength="10"
                                maxlength="10" />
                        </div>
                        <div class="col-xl-12">
                            <label class="form-label">Email</label>
                            <input type="text" class="form-control" formControlName="email"
                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" />
                        </div>
                        <div class="col-xl-12">
                            <label class="form-label">Shop Name</label>
                            <input type="text" class="form-control" formControlName="shopname" />
                        </div>
                        <div class="col-xl-12">
                            <label class="form-label">Description</label>
                            <input type="text" class="form-control" formControlName="msg_description" />
                        </div>
                        <div class="col-xl-12">
                            <label class="form-label">Adress</label>
                            <input type="text" class="form-control" formControlName="reg_address" />
                        </div>
                        <div class="col-xl-12">
                            <label class="form-label"></label>
                        </div>
                        <div class="page-title-right" style="text-align: center ; ">
                            <button class="button-customize"
                                style="background-color: #f175cd;width: 100px;height:40px ">submit</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>
<app-footer-style-two></app-footer-style-two>