import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { BannerService } from '../../banner.service';

@Component({
  selector: 'app-clinicalservices',
  templateUrl: './clinicalservices.component.html',
  styleUrls: ['./clinicalservices.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ClinicalservicesComponent {
  page: number = 1;
  clinicaldata: any = [];
  serviceForm: FormGroup;
  constructor(private bannerservice: BannerService, private formBuilder: FormBuilder,) { }
  // public clinicaldata = clinicaldata;

  ngOnInit(): void {
    this.getclinicalervices();
    this.serviceForm = this.formBuilder.group({
      course_name: ["", [Validators.required]],
      name: ["", [Validators.required]],
      email: ["", [Validators.required]],
      phonenumber: ["", [Validators.required]],
      reg_address: ["", [Validators.required]],
    })
  }
  getclinicalervices() {
    this.bannerservice.getclinicalervices().subscribe((res: any) => {
      console.log(31, res.data);
      this.clinicaldata = res.data;
      console.log(this.clinicaldata  );
      

    })
  }

  submitservices() {
    console.log(this.serviceForm.value)

    if (this.serviceForm.invalid) {
      this.statusChangeAlert("Please Fill All The Details")
      return;
    } else {
      var course_id = this.serviceForm.value.course_name.split(',')[0];
      console.log(course_id);
      var coursename = this.serviceForm.value.course_name.split(',')[1];
      console.log(coursename);
      this.serviceForm.value.course_id = course_id
      this.serviceForm.value.coursename = coursename
      console.log(this.serviceForm.value)
      this.bannerservice.postclinicaldata(this.serviceForm.value).subscribe(
        (res) => {
          if (res.status == 200) {
            this.statusChangeAlertdata(this.serviceForm.value.coursename);
            this.serviceForm.reset();
          } else {
            this.serviceForm.reset();
            this.statusChangeAlert("Please Check The Details")

          }
        }, error => {
        })
    }
  }



  numberOnlyValidation(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  statusChangeAlertdata(message: any) {
    Swal.fire({
      icon: 'success',
      title: message,
      text: "Has Been Sucesfully You Will Recieve Message Regarding This Course.",
      showConfirmButton: false,
      timer: 3000,
    })
  }

  statusChangeAlert(message: any) {
    Swal.fire({
      icon: 'error',
      // text: message,
      title: message,
      showConfirmButton: false,
      timer: 3000,
    })
  }

}
