import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { AboutComponent } from './components/pages/about/about.component';
import { TeamComponent } from './components/pages/team/team.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { CategoryComponent } from './components/pages/category/category.component';
import { ShopComponent } from './components/pages/shop/shop.component';
import { CartComponent } from './components/pages/cart/cart.component';
import { CheckoutComponent } from './components/pages/checkout/checkout.component';
import { ProductsDetailsComponent } from './components/pages/products-details/products-details.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { LoginRegisterComponent } from './components/pages/login-register/login-register.component';
import { TermsConditionComponent } from './components/pages/terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ListingComponent } from './components/pages/listing/listing.component';
import { ListingDetailsComponent } from './components/pages/listing-details/listing-details.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ClinicalservicesComponent } from './components/pages/clinicalservices/clinicalservices.component';
import { SaloonservicesComponent } from './components/pages/Saloonservices/Saloonservices.component';

const routes: Routes = [
    { path: 'home-three', component: HomeThreeComponent },
    { path: 'about', component: AboutComponent },
    { path: 'courses', component: TeamComponent },
    { path: 'pricing', component: PricingComponent },
    { path: 'category', component: CategoryComponent },
    { path: 'shop', component: ShopComponent },
    { path: 'cart', component: CartComponent },
    { path: 'checkout', component: CheckoutComponent },
    { path: 'products-details/:id', component: ProductsDetailsComponent },
    { path: 'faq', component: FaqComponent },
    { path: 'testimonials', component: TestimonialsComponent },
    { path: 'error', component: ErrorComponent },
    { path: 'login-register', component: LoginRegisterComponent },
    { path: 'terms-condition', component: TermsConditionComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: 'coming-soon', component: ComingSoonComponent },
    { path: 'blog', component: ListingComponent },
    { path: 'listing-details', component: ListingDetailsComponent },
    { path: 'gallery', component: BlogComponent },
    { path: 'blog-details/:id', component: BlogDetailsComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'clinicalservices', component: ClinicalservicesComponent },
    { path: 'Saloonservices', component: SaloonservicesComponent },
    // Here add new pages component

    { path: '**', component: HomeThreeComponent } // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }