import { Component, OnInit } from '@angular/core';
import { BannerService } from '../../banner.service';

@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss']
})
export class ListingComponent implements OnInit {
  blogs: any;

  constructor(private bannerservice: BannerService,) { }

  ngOnInit(): void {
    this.getblogs();
  }

  getblogs() {
    this.bannerservice.getblogs().subscribe((res: any) => {
      console.log(res);
      this.blogs = res.data;
    })
  }

}
