<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg6">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Privacy Policy</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Pages</li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Privacy Policy</li>
            </ul>
        </div>
    </div>
</div>

<div class="privacy-policy-area ptb-100">
    <div class="container">
        <div class="single-content">
            <h3>Welcome to Pilar Privacy & Policy</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam venenatis purus arcu. Sed vitae porttitor
                lectus. Aenean ac odio quis est suscipit vehicula nec eget arcu. Mauris malesuada libero eu ex pharetra,
                ac aliquam justo vestibulum. Fusce vulputate est sed orci cursus efficitur. Sed volutpat sit amet mauris
                eget posuere. Donec a mi eu dolor tincidunt pulvinar quis eleifend diam. Vestibulum diam diam, ultricies
                id cursusac, bibendum ac quam.Donec posuere diam eget consequat facilisis. Vestibulum turpis mauris,
                hendrerit ut risus euismod, maximus iaculis nulla. Quisque tincidunt augue nunc, non pretium nibh
                consequat vel. Morbi ornare quam neque, nec pharetra magna dapibus quis. Curabitur vehicula ultricies
                faucibus.</p>
            <p>Integer mattis magna at nibh semper vulputate. Nunc eu gravida risus, at lobortis ipsum. Suspendisse
                tempus metus vitae interdum luctus. Pellentesque lectus lorem, laoreet in semper vitae, pellentesque sit
                amet sapien. Morbi ut nibh a massa suscipit posuere. Nam venenatis eget justo id pulvinar. Quisque
                dignissim eget turpis nec pulvinar. Vestibulum fermentum lacus sed dignissim mattis. Aenean porttitor
                vitae lacus nec pellentesque. Nulla sodales vehicula scelerisque. Vestibulum sed auctor arcu, vitae
                finibus dolor. Nam ultrices consequat odio at gravida.</p>
        </div>
        <div class="single-content">
            <h3>Welcome to Product Pilar Privacy</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam venenatis purus arcu. Sed vitae porttitor
                lectus. Aenean ac odio quis est suscipit vehicula nec eget arcu. Mauris malesuada libero eu ex pharetra,
                ac aliquam justo vestibulum. Fusce vulputate est sed orci cursus efficitur. Sed volutpat sit amet mauris
                eget posuere. Donec a mi eu dolor tincidunt pulvinar quis eleifend diam. Vestibulum diam diam, ultricies
                id cursusac, bibendum ac quam.Donec posuere diam eget consequat facilisis. Vestibulum turpis mauris,
                hendrerit ut risus euismod, maximus iaculis nulla. Quisque tincidunt augue nunc, non pretium nibh
                consequat vel. Morbi ornare quam neque, nec pharetra magna dapibus quis. Curabitur vehicula ultricies
                faucibus.</p>
            <p>Integer mattis magna at nibh semper vulputate. Nunc eu gravida risus, at lobortis ipsum. Suspendisse
                tempusmetus vitae interdum luctus. Pellentesque lectus lorem, laoreet in semper vitae, pellentesque sit
                amet sapien. Morbi ut nibh a massa suscipit posuere. Nam venenatis eget justo id pulvinar. Quisque
                dignissim eget turpis necpulvinar. Vestibulum fermentum lacus sed dignissim mattis. Aenean porttitor
                vitae lacus nec pellentesque. Nulla sodales vehicula scelerisque. Vestibulum sed auctor arcu, vitae
                finibus dolor. Nam ultrices consequat odio at gravida.</p>
        </div>
        <div class="single-content">
            <h3>Welcome to Services Pilar Privacy</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam venenatis purus arcu. Sed vitae porttitor
                lectus. Aenean ac odio quis est suscipit vehicula nec eget arcu. Mauris malesuada libero eu ex pharetra,
                ac aliquam justo vestibulum. Fusce vulputate est sed orci cursus efficitur. Sed volutpat sit amet mauris
                eget posuere. Donec a mi eu dolor tincidunt pulvinar quis eleifend diam. Vestibulum diam diam, ultricies
                id cursusac, bibendum ac quam.Donec posuere diam eget consequat facilisis. Vestibulum turpis mauris,
                hendrerit ut risus euismod, maximus iaculis nulla. Quisque tincidunt augue nunc, non pretium nibh
                consequat vel. Morbi ornare quam neque, nec pharetra magna dapibus quis. Curabitur vehicula ultricies
                faucibus.</p>
            <p>Integer mattis magna at nibh semper vulputate. Nunc eu gravida risus, at lobortis ipsum. Suspendisse
                tempus metus vitae interdum luctus. Pellentesque lectus lorem, laoreet in semper vitae, pellentesque sit
                amet sapien. Morbi ut nibh a massa suscipit posuere. Nam venenatis eget justo id pulvinar. Quisque
                dignissim eget turpis nec pulvinar. Vestibulum fermentum lacus sed dignissim mattis. Aenean porttitor
                vitae lacus nec pellentesque. Nulla sodales vehicula scelerisque. Vestibulum sed auctor arcu, vitae
                finibus dolor. Nam ultrices consequat odio at gravida.</p>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>