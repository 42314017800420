import { Component, OnInit } from '@angular/core';
import { BannerService } from '../../banner.service';
import { OwlCarousel } from 'ngx-owl-carousel';
import { ActivatedRoute } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-home-three',
  templateUrl: './home-three.component.html',
  styleUrls: ['./home-three.component.scss']
})
export class HomeThreeComponent implements OnInit {
  banners: any;
  value: boolean;
  blogs: any;
  imagedata: any[] = [];
  updates: any;
  testimonials: any;
  serviceForm: FormGroup;
  coursesdata: any;
  visitcount: any;

  constructor(private bannerservice: BannerService, private activatedRoute: ActivatedRoute, private modal: NgbModal, private formBuilder: FormBuilder,) {


  }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 300,
    autoplay: true,
    autoplayTimeout: 5000,
    navText: ['&#8249', '&#8250;'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      760: {
        items: 3
      },
      1000: {
        items: 4
      }
    },
    nav: true
  }


  testimonialOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 300,
    autoplay: true,
    autoplayTimeout: 5000,
    navText: ['&#8249', '&#8250;'],
    responsive: {
      0: {
        items: 1
      }
    },
    nav: true
  }


  bannerOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 300,
    autoplay: true,
    autoplayTimeout: 5000,
    navText: ['&#8249', '&#8250;'],
    responsive: {
      0: {
        items: 1
      }
    },
    // nav: true
  }


  ngOnInit(): void {
    this.get();
    this.getcoursesdata();
    this.gethomebanners();
    this.getblogs();
    this.getupdatesdata();
    this.gettestmonial();
    this.serviceForm = this.formBuilder.group({
      course_name: ["", [Validators.required]],
      name: ["", [Validators.required]],
      email: ["", [Validators.required]],
      phonenumber: ["", [Validators.required]],
      reg_address: ["", [Validators.required]],
    })

  }

  getcoursesdata() {
    this.bannerservice.getcoursesdata().subscribe((res: any) => {
      console.log(res);
      this.coursesdata = res.data;
    })
  }

  gethomebanners() {
    this.bannerservice.gethomebanners().subscribe((res: any) => {
      console.log(res.data);
      this.banners = res.data;
      this.value = true;

    })
  }

  getblogs() {
    this.bannerservice.getblogs().subscribe((res: any) => {
      console.log(res);
      this.blogs = res.data;
    })
  }

  getupdatesdata() {
    this.bannerservice.getupdatesdata().subscribe((res: any) => {
      console.log(res.data);
      this.updates = res.data;
    })
  }

  gettestmonial() {
    this.bannerservice.gettestmonial().subscribe((res: any) => {
      console.log(res.data);
      this.testimonials = res.data
    })
  }


  open(openmodal) {
    this.modal.open(openmodal, { centered: true, size: 'l' })
  }

  closr() {
    this.modal.dismissAll();
    this.serviceForm.reset();

  }


  submitservices() {
    if (this.serviceForm.invalid) {
      this.statusChangeAlert("Please Fill All The Details")
      return;
    } else {
      var course_id = this.serviceForm.value.course_name.split(',')[0];
      console.log(course_id);
      var coursename = this.serviceForm.value.course_name.split(',')[1];
      console.log(coursename);
      this.serviceForm.value.course_id = course_id
      this.serviceForm.value.coursename = coursename
      console.log(this.serviceForm.value)
      this.bannerservice.postcoursesregdata(this.serviceForm.value).subscribe(
        (res) => {
          if (res.status == 200) {
            this.statusChangeAlertdata(this.serviceForm.value.coursename);
            this.serviceForm.reset();
          } else {
            this.serviceForm.reset();
            this.statusChangeAlert("Please Check The Details")

          }
        }, error => {
        })
    }
  }

  numberOnlyValidation(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  statusChangeAlertdata(message: any) {
    Swal.fire({
      icon: 'success',
      title: message,
      text: "Has Been Sucesfully You Will Recieve Message Regarding This Course.",
      showConfirmButton: false,
      timer: 3000,
    })
  }

  statusChangeAlert(message: any) {
    Swal.fire({
      icon: 'error',
      // text: message,
      title: message,
      showConfirmButton: false,
      timer: 3000,
    })
  }
  get() {
    this.bannerservice.cutnApi().subscribe((res: any) => {
      this.visitcount = res.value;
      console.log(this.visitcount);

    })
  }

}
