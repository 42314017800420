<app-navbar-style-two></app-navbar-style-two>
<div class="inner-banner inner-bg5">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Gallery</h3>
            <ul>
                <li><a href="/">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Gallery</li>
            </ul>
        </div>
    </div>
</div>
<div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Gallery</span>
            <h2>Latest Images <b>Post</b></h2>
        </div>
        <div class="row pt-45">
            <div class="col-lg-4 col-md-6"
                *ngFor="let item of gallerydata | paginate: { itemsPerPage: 6, currentPage: page }">
                <div class=" blog-card">
                    <a routerLink="/products-details/{{item.id}}"><img src="{{item.image}}" alt="Images"
                            style="width: 650px; height: 365px;"></a>
                    <div class="content" style="height: 110px;">
                        <h3>
                            <a routerLink="/products-details/{{item.id}}">{{item.categoryname}}</a>
                        </h3>
                    </div>
                </div>
            </div>
            <div class="pagination justify-content-center mt-50">
                <pagination-controls (pageChange)="page = $event" responsive="true" previousLabel="<"
                    nextLabel=">"></pagination-controls>
            </div>
            <!-- <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/products-details"><img src="assets/img/jasmine/b1.jpg" alt="Images"></a>
                    <div class="content">
                        <h3>
                            <a routerLink="/products-details">Beauty School</a>
                        </h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/jasmine/b1.jpg" alt="Images"></a>
                    <div class="content">
                        <h3>
                            <a routerLink="/blog-details">Beauty School</a>
                        </h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/products-details"><img src="assets/img/jasmine/b1.jpg" alt="Images"></a>
                    <div class="content">
                        <h3>
                            <a routerLink="/products-details">Beauty School</a>
                        </h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/jasmine/b1.jpg" alt="Images"></a>
                    <div class="content">
                        <h3>
                            <a routerLink="/blog-details">Beauty School</a>
                        </h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/jasmine/b1.jpg" alt="Images"></a>
                    <div class="content">
                        <h3>
                            <a routerLink="/blog-details">Beauty School</a>
                        </h3>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>
<app-footer-style-two></app-footer-style-two>