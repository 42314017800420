<footer class="footer-area footer-bg2">
    <div class="footer-middle pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="footer-widget">
                        <a routerLink="/" class="logo"><img src="assets/img/jasmine/83-x-66.png" alt="Logo"></a>
                        <p>Japanese technology now available only at Jasmines Cmcc & Jasmines Cmcc Be_U_Ty School.</p>
                        <ul class="footer-contact-list">
                            <li>Beauty Salon in Danavai Peta
                                Open today until 8:00 PM</li>
                            <li><span>Phone :</span> <a href="tel:083411 88186"> 083411 88186</a></li>
                            <li><span>Email :</span> <a href="beautyschool9@gmail.com">beautyschool9@gmail.com</a></li>
                        </ul>
                        <ul class="social-link">
                            <li><a href="https://www.google.com/search?hl=en-IN&gl=in&q=JASMINES+CMCC+%26+JASMINES+CMCC+BE-U-TY+SCHOOL,+46-13-28,+Doctor+Raja+Babu+Hospital+Street+Gandhi+park+back+side,+Danavai+Peta,+Rajahmundryahendravaram,+Andhra+Pradesh+533103&ludocid=1034611851578891184&lsig=AB86z5U1cCI41kcPYf9TIrBnVudY#lrd=0x3a37a3f4f0daff3f:0xe5badff55c4dbb0,1"
                                    target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                        </ul>
                    </div>
                </div>
                <!-- <div class="col-lg-2 col-md-6">
                    <div class="footer-widget pl-1">
                        <h3>SUPPORT</h3>
                        <ul class="footer-list">
                            <li><a routerLink="/">My Account</a></li>
                            <li><a routerLink="/checkout">Checkout</a></li>
                            <li><a routerLink="/cart">Cart</a></li>
                            <li><a routerLink="/faq">FAQ's</a></li>
                            <li><a routerLink="/login-register">Register</a></li>
                            <li><a routerLink="/">Help & Support</a></li>
                        </ul>
                    </div>
                </div> -->
                <div class="col-lg-4 col-md-6">
                    <div class="footer-widget pl-5">
                        <h3>QUICK LINKS</h3>
                        <ul class="footer-list">
                            <li><a routerLink="/about">About Us</a></li>
                            <li><a routerLink="/courses">Courses</a></li>
                            <li><a routerLink="/blog">Blog</a></li>
                            <li><a routerLink="/gallery">Gallery</a></li>
                            <li><a routerLink="/contact">Contact Us</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="footer-widget">
                        <h3>NEWSLETTER</h3>
                        <p>To get the latest news and latest updates from us</p>
                        <div class="footer-form">
                            <form>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <input type="email" name="email" id="email" class="form-control" required
                                                data-error="Please enter your email" placeholder="Your Email*"
                                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">

                                        <button type="submit" class="default-btn"><a
                                                href="mailto:beautyschool8@gmail.com" target="_blank">SUBSCRIBE
                                                now</a></button>

                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>





               
            </div>
        </div>
    </div>
</footer>
<div class="copy-right-area copy-right-area-two">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-4">
                <div class="copy-right-list">
                    <ul>
                        <li><a routerLink="/terms-condition">Terms of Use</a></li>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <!-- <li><a routerLink="/blog" target="_blank">Blog</a></li> -->
                    </ul>
                </div>
            </div>
            <div class="col-lg-8 col-md-8">
                <div class="copy-right-text text-end">
                    <p>© Developed By<a href="https://amaravathisoftware.com/" target="_blank">Amaravathi Software
                            Innvoations</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>