<app-navbar-style-two></app-navbar-style-two>
<div class="inner-banner inner-bg11">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Blog</h3>
            <ul>
                <li><a href="/">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Blog</li>
            </ul>
        </div>
    </div>
</div>
<div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Blog</span>
            <h2>Latest Blog <b>Post</b></h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6" *ngFor="let item of blogs ">
                <div class="city-card">
                    <a routerLink="/blog-details/{{item.id}}">
                        <img src="{{item.image}}" alt="Images">
                    </a>
                    <div class="content">
                        <a routerLink="/blog-details/{{item.id}}">
                            <h3>{{item.name}}</h3>
                        </a>
                        <!-- <span>17 Locations</span> -->
                    </div>
                </div>
            </div>

            <!-- <div class="col-lg-4 col-md-6">
                <div class="city-card">
                    <a routerLink="/"><img src="assets/img/jasmine/hh2.jpg" alt="Images"></a>
                    <div class="content">
                        <a routerLink="/">
                            <h3>Boby Polishing </h3>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="city-card">
                    <a routerLink="/"><img src="assets/img/jasmine/b3.jpg" alt="Images"></a>
                    <div class="content">
                        <a routerLink="/">
                            <h3>Bridal Makeup</h3>
                        </a>
                    </div>
                </div>
            </div> -->

            <!-- <div class="col-lg-7 col-md-6">
                <div class="city-card">
                    <a routerLink="/"><img src="assets/img/city-area/city-area8.jpg" alt="Images"></a>
                    <div class="content">
                        <a routerLink="/">
                            <h3>Boby Polishing </h3>
                        </a>
                        <span>30 Locations</span>
                    </div>
                </div>
            </div>

           <div class="col-lg-5 col-md-6 offset-md-3 offset-lg-0">
                <div class="city-card">
                    <a routerLink="/"><img src="assets/img/city-area/city-area9.jpg" alt="Images"></a>
                    <div class="content">
                        <a routerLink="/">
                            <h3>Bridal Makeup</h3>
                        </a>
                        <span>34 Locations</span
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>
<app-footer-style-two></app-footer-style-two>