<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg6">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Frequently Asked Questions</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Pages</li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Frequently Asked Questions</li>
            </ul>
        </div>
    </div>
</div>

<div class="faq-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>FAQ</span>
            <h2>Listing <b>FAQ</b></h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-6">
                <div class="faq-item">
                    <h3>Q: How Can I Create a Free Listing?</h3>
                    <p><strong>A:</strong>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin placerat, metus ut tincidunt vestibulum,nunc tellus fringilla lorem, ut auctor metus felis vel est. Mauris tristique leo nulla, id blandit sem malesuada ac. Orci varius natoque penatibus et magnis dis parturient montes.</p>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="faq-item">
                    <h3>Q: How Much Time Take It Get a Approval?</h3>
                    <p><strong>A:</strong>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin placerat, metus ut tincidunt vestibulum,nunc tellus fringilla lorem, ut auctor metus felis vel est. Mauris tristique leo nulla, id blandit sem malesuada ac. Orci varius natoque penatibus et magnis dis parturient montes.</p>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="faq-item">
                    <h3>Q: Is It My Listing?</h3>
                    <p><strong>A:</strong>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin placerat, metus ut tincidunt vestibulum,nunc tellus fringilla lorem, ut auctor metus felis vel est. Mauris tristique leo nulla, id blandit sem malesuada ac. Orci varius natoque penatibus et magnis dis parturient montes.</p>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="faq-item">
                    <h3>Q: Is My Listing Secure?</h3>
                    <p><strong>A:</strong>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin placerat, metus ut tincidunt vestibulum,nunc tellus fringilla lorem, ut auctor metus felis vel est. Mauris tristique leo nulla, id blandit sem malesuada ac. Orci varius natoque penatibus et magnis dis parturient montes.</p>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="faq-item">
                    <h3>Q:  How To Make It Use Easily?</h3>
                    <p><strong>A:</strong>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin placerat, metus ut tincidunt vestibulum,nunc tellus fringilla lorem, ut auctor metus felis vel est. Mauris tristique leo nulla, id blandit sem malesuada ac. Orci varius natoque penatibus et magnis dis parturient montes.</p>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="faq-item">
                    <h3>Q: What is The Premium Listing?</h3>
                    <p><strong>A:</strong>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin placerat, metus ut tincidunt vestibulum,nunc tellus fringilla lorem, ut auctor metus felis vel est. Mauris tristique leo nulla, id blandit sem malesuada ac. Orci varius natoque penatibus et magnis dis parturient montes.</p>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="faq-item">
                    <h3>Q: Who Operates the Directory?</h3>
                    <p><strong>A:</strong>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin placerat, metus ut tincidunt vestibulum,nunc tellus fringilla lorem, ut auctor metus felis vel est. Mauris tristique leo nulla, id blandit sem malesuada ac. Orci varius natoque penatibus et magnis dis parturient montes.</p>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="faq-item">
                    <h3>Q: How Do I Check or Update My Company Listing?</h3>
                    <p><strong>A:</strong>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin placerat, metus ut tincidunt vestibulum,nunc tellus fringilla lorem, ut auctor metus felis vel est. Mauris tristique leo nulla, id blandit sem malesuada ac. Orci varius natoque penatibus et magnis dis parturient montes.</p>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>