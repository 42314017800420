import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { delay, map } from 'rxjs/operators';
import { of } from 'rxjs';



var apis = 'https://jasminescmcc.com:8791/dashboardapi/'

@Injectable({
  providedIn: 'root'
})
export class BannerService {

  constructor(private httpClient: HttpClient) { }


  gethomebanners() {
    return this.httpClient.get(apis + 'gethomebanners')

  }

  getblogs() {
    return this.httpClient.post<any>(apis + 'getblogs', []).pipe(map(res => {
      return res;
    }, (error: any) => {
      return error;
    }));

  }


  getupdatesdata() {
    return this.httpClient.get(apis + 'getupdatesdata')

  }

  gettestmonial() {
    return this.httpClient.post<any>(apis + 'gettestmonial', []).pipe(map(res => {
      return res;
    }, (error: any) => {
      return error;
    }));
  }

  postcontactdata(data) {
    return this.httpClient.post<any>(apis + 'postcontactdata', data).pipe(map(res => {
      return res;
    }, (error: any) => {
      return error;
    }));
  }

  postQuotesdata(data) {
    return this.httpClient.post<any>(apis + 'postQuotesdata', data).pipe(map(res => {
      return res;
    }, (error: any) => {
      return error;
    }));
  }

  postcoursesregdata(data) {
    return this.httpClient.post<any>(apis + 'postcoursesregdata', data).pipe(map(res => {
      return res;
    }, (error: any) => {
      return error;
    }));
  }

  getcoursesdata() {
    return this.httpClient.post<any>(apis + 'getcoursesdata', []).pipe(map(res => {
      return res;
    }, (error: any) => {
      return error;
    }));

  }

  getCatenamedata() {
    return this.httpClient.get(apis + 'getCatenamedata')

  }


  getgallerydata11() {
    return this.httpClient.get(apis + 'getgallerydata11')

  }

  getsalonservices() {
    return this.httpClient.get(apis + 'getsalonservices')

  }

  getclinicalervices() {
    return this.httpClient.get(apis + 'getclinicalervices')

  }



  postclinicaldata(data) {
    return this.httpClient.post<any>(apis + 'postclinicalservregdata', data).pipe(map(res => {
      return res;
    }, (error: any) => {
      return error;
    }));
  }


  postsalonservicesdata(data) {
    return this.httpClient.post<any>(apis + 'postsalooonservregdata', data).pipe(map(res => {
      return res;
    }, (error: any) => {
      return error;
    }));
  }

  postjasminesstaffFormdata(data) {
    return this.httpClient.post<any>(apis + 'postjasminesstaffFormdata', data).pipe(map(res => {
      return res;
    }, (error: any) => {
      return error;
    }));
  }

  postjasminesotherdata(data) {
    return this.httpClient.post<any>(apis + 'postjasminesotherdata', data).pipe(map(res => {
      return res;
    }, (error: any) => {
      return error;
    }));
  }

  cutnApi() {
    return this.httpClient.get('https://api.countapi.xyz/hit/jasminescmcc')
  }






}
