<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img src="assets/img/jasmine/83-x-66.png" alt="Logo"></a>
    </div>
    <div class="main-nav">
        <div class="container-fluid">
            <nav class="container-max navbar navbar-expand-md navbar-light ">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/jasmine/83-x-66.png" alt="Logo"></a>
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto">

                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Home </a></li>
                        <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">About Us</a></li>
                        <li class="nav-item"><a routerLink="/courses" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Courses</a></li>
                        <li class="nav-item"><a routerLink="/clinicalservices" class="nav-link"
                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Clinical
                                Services</a>
                        </li>
                        <li class="nav-item"><a routerLink="/Saloonservices" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Salon Services</a></li>
                        <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Blog</a></li>
                        <li class="nav-item"><a routerLink="/gallery" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Gallery</a></li>
                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Contact US</a></li>


                    </ul>
                    <div class="side-nav d-in-line align-items-center">
                        <div class="side-item">
                            <div class="nav-add-btn" (click)="open(openmodel)">
                                <a class="default-btn border-radius">Franchise<i class='bx bx-notepad'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="header-contact">
                        <div>
                            <a href="tel:+919999999999"><i class="bx bxs-phone-call"></i> Call now</a>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="header-contact">
                        <div>
                            <a target="_blank"
                                href="https://www.google.com/maps/place/JASMINES+CMCC+%26+JASMINES+CMCC+BE-U-TY+SCHOOL/@17.0083314,81.780055,17z/data=!3m1!4b1!4m5!3m4!1s0x3a37a3f4f0daff3f:0xe5badff55c4dbb0!8m2!3d17.0083263!4d81.7822437">
                                <i class="bx bxs-location-plus"></i> Directions</a>
                        </div>
                    </div>
                </div>

            </nav>
        </div>
    </div>
    <div class="side-nav-responsive">
        <div class="container">
            <div class="container">
                <div class="side-nav-inner">
                    <div class="side-nav justify-content-center  align-items-center">


                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="search-overlay">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-layer"></div>
            <div class="search-layer"></div>
            <div class="search-layer"></div>
            <div class="search-close">
                <span class="search-close-line"></span>
                <span class="search-close-line"></span>
            </div>
            <div class="search-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class="flaticon-loupe"></i></button>
                </form>
            </div>
        </div>
    </div>
</div>
<ng-template #openmodel let-modal>
    <div>
        <div class="modal-header">
            <h5 class="modal-title mt-0 ">Get Quote </h5>
            <button type="button" class="close" aria-hidden="true" (click)="closr()">×</button>
        </div>
        <div class="modal-body">
            <form (ngSubmit)="submitservices()" [formGroup]="serviceForm">
                <div class="col-xl-12">
                    <div class="row">
                        <div class="col-xl-12">
                            <label class="form-label">Name</label>
                            <input type="text" class="form-control" formControlName="name" />
                        </div>
                        <div class="col-xl-12">
                            <label class="form-label">Phone Number</label>
                            <input type="text" class="form-control" formControlName="phonenumber"
                                (keypress)="numberOnlyValidation($event)"
                                onkeyup="this.value = this.value.replace(/[^a-zA-Z0-9]/g, '')" minlength="10"
                                maxlength="10" />
                        </div>
                        <div class="col-xl-12">
                            <label class="form-label">Email</label>
                            <input type="text" class="form-control" formControlName="email" />
                        </div>
                        <div class="col-xl-12">
                            <label class="form-label">Description</label>
                            <input type="text" class="form-control" formControlName="message_desc" />
                        </div>
                        <div class="col-xl-12">
                            <label class="form-label"></label>
                        </div>
                        <div class="page-title-right" style="text-align: center ; ">
                            <button class="button-customize"
                                style="background-color: #f175cd;width: 100px;height:40px ">submit</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>