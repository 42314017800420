<app-navbar-style-two></app-navbar-style-two>
<div class="inner-banner inner-bg10">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Gallery</h3>
            <ul>
                <li><a href="/">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Gallery-details</li>
            </ul>
        </div>
    </div>
</div>
<!-- <div class="inner-banner inner-bg5">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Gallery</h3>
            <ul>
                <li><a href="/">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Gallery-details</li>
            </ul>
        </div>
    </div>
</div>


<div class="services-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12" *ngFor="let item of gallery | paginate: { itemsPerPage: 9, currentPage: page }">
                <div class="gallery-img">
                    <a class="image-popup">
                        <img [src]="item.image" class="img-fluid" alt="">
                    </a>
                </div>
            </div>
           <h3 style="text-align: center;font-size: 35px;">Gallery Image</h3>
            <div class="row">
                <div class="col-lg-4 col-md-8 col-sm-8" >
                    <div class="single-product-box"     style="width: 415px;height:240px ;">
                        <div class="product-images justy" style="margin-top: 15%;">
                            <img src="assets/img/jasmine/b2.jpg" 
                            >
                           
                        </div>
                    </div>
                </div>       
               
            </div>
            <div class="col-lg-12 text-center pt-100">
                <div class="pagination justify-content-center mt-30">
                    <pagination-controls (pageChange)="page = $event" responsive="true" previousLabel="< Prev" nextLabel="Next >">
                    </pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="scrives-item-3 mt-4">
    <div class="row align-items-center">
        <div class="col-lg-6 col-sm-6">
            <div class="social">
               
            </div>
        </div>
    
    </div>
</div> -->
<!-- <ng-template #openmodal style="width: 500px;height:500px;">
    <div class="row" id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
        <div class="w3-content w3-display-container" class="carousel-inner">
            <div *ngIf="showi" class="carousel-item active">
                <img src="{{modalimage1}}" style="width: 100%; height:auto;"  class="d-block w-100" alt="...">
                <button type="button" class="close" aria-hidden="true" (click)="modalDismiss()" class="cross"
                    style="margin-left: 99%;">×</button>
            </div>
            <div class="w3-display-container mySlides" *ngFor="let img of gallery" >
                <img src="{{img.image}}" style="width: 100%; height:auto;"  class="d-block w-100" alt="...">
                <button type="button" class="close" aria-hidden="true" (click)="modalDismiss()" class="cross"
                    style="margin-left: 99%;">×</button>
            </div>
        </div>
        <button class="w3-button w3-display-left w3-black" style="width:10px;" onclick="plusDivs(-1)"
            data-bs-slide="prev" (click)="close()" data-bs-target="#carouselExampleControls">&#10094;</button>
        <button class="w3-button w3-display-right w3-black" style="width:10px;" onclick="plusDivs(1)" (click)="close()"
            data-bs-target="#carouselExampleControls" data-bs-slide="next">&#10095;</button>
    </div>
</ng-template> -->


<div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Gallery</span>
            <h2>Latest Images <b>Post</b></h2>
        </div>
        <div class="row pt-45">
            <div class="col-lg-4 col-md-6" *ngFor="let item of galleryddetails">
                <div class="blog-card">
                    <img src="{{item.image}}" alt="Images" style="width: 650px; height: 365px;">

                </div>
            </div>

            <div class="pagination justify-content-center mt-50">
                <pagination-controls (pageChange)="page = $event" responsive="true" previousLabel="<"
                    nextLabel=">"></pagination-controls>
            </div>
            <!-- <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <img src="assets/img/jasmine/1/008.png" alt="Images">
                   
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <img src="assets/img/jasmine/1/007.png" alt="Images">
                  
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
              <img src="assets/img/jasmine/1/006.png" alt="Images">
                   
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <img src="assets/img/jasmine/1/005.png" alt="Images">
                    
                </div>
            </div> 
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <img src="assets/img/jasmine/1/004.png" alt="Images">
                   
                </div>
            </div> -->
        </div>
    </div>
</div>
<app-footer-style-two></app-footer-style-two>