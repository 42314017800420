import { Component, OnInit } from '@angular/core';
import { BannerService } from '../../banner.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  page: number = 1;

  gallerydata: any;

  constructor(private bannerservice: BannerService,) { }

  ngOnInit(): void {
    this.getgallery();
  }

  getgallery() {
    this.bannerservice.getCatenamedata().subscribe((res: any) => {
      console.log(res);
      this.gallerydata = res.data;
    })

  }

}
