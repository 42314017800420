import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BannerService } from '../../banner.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-navbar-style-two',
  templateUrl: './navbar-style-two.component.html',
  styleUrls: ['./navbar-style-two.component.scss']
})
export class NavbarStyleTwoComponent implements OnInit {
  serviceForm: FormGroup;
  constructor(private modal: NgbModal, private formBuilder: FormBuilder, private bannerservice: BannerService,) {
    this.serviceForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      email: ["", [Validators.required]],
      phonenumber: ["", [Validators.required]],
      message_desc: ["", [Validators.required]],
    })
  }

  ngOnInit(): void {
  }

  open(openmodal) {
    this.modal.open(openmodal, { centered: true, size: 'l' })
  }

  closr() {
    this.modal.dismissAll();
    this.serviceForm.reset();

  }

  submitservices() {
    if (this.serviceForm.invalid) {
      this.statusChangeAlert("Please Fill All The Details")
      return;
    } else {
      console.log(this.serviceForm.value)
      this.bannerservice.postQuotesdata(this.serviceForm.value).subscribe(
        (res) => {
          this.statusChangeAlertdata("Franchise Acceptance Will Be Updated To You Soon");
          this.serviceForm.reset();
          this.closr();
        }, error => {
        })
    }
  }


  numberOnlyValidation(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  statusChangeAlertdata(message: any) {
    Swal.fire({
      icon: 'success',
      // text: message,
      title: message,
      showConfirmButton: false,
      timer: 3000,
    })
  }

  statusChangeAlert(message: any) {
    Swal.fire({
      icon: 'error',
      // text: message,
      title: message,
      showConfirmButton: false,
      timer: 3000,
    })
  }






}
