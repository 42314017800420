import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { BannerService } from '../../banner.service';
// import coursesdata from 'src/app/components/data/courses.json'
@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {


  page: number = 1;
  coursesdata: any;
  serviceForm: FormGroup;
  constructor(private modal: NgbModal, private formBuilder: FormBuilder, private bannerservice: BannerService,) { }
  // public coursesdata = coursesdata;

  ngOnInit(): void {
    this.getcoursesdata();
    this.serviceForm = this.formBuilder.group({
      course_name: ["", [Validators.required]],
      name: ["", [Validators.required]],
      email: ["", [Validators.required]],
      phonenumber: ["", [Validators.required]],
      reg_address: ["", [Validators.required]],
    })
  }

  getcoursesdata() {
    this.bannerservice.getcoursesdata().subscribe((res: any) => {
      console.log(res);
      this.coursesdata = res.data;
    })
  }

  open(openmodal) {
    this.modal.open(openmodal, { centered: true, size: 'l' })
  }

  closr() {
    this.modal.dismissAll();
    this.serviceForm.reset();

  }


  submitservices() {
    if (this.serviceForm.invalid) {
      this.statusChangeAlert("Please Fill All The Details")
      return;
    } else {
      var course_id = this.serviceForm.value.course_name.split(',')[0];
      console.log(course_id);
      var coursename = this.serviceForm.value.course_name.split(',')[1];
      console.log(coursename);
      this.serviceForm.value.course_id = course_id
      this.serviceForm.value.coursename = coursename
      console.log(this.serviceForm.value)
      this.bannerservice.postcoursesregdata(this.serviceForm.value).subscribe(
        (res) => {
          if (res.status == 200) {
            this.statusChangeAlertdata(this.serviceForm.value.coursename);
            this.serviceForm.reset();
          } else {
            this.serviceForm.reset();
            this.statusChangeAlert("Please Check The Details")

          }
        }, error => {
        })
    }
  }



  numberOnlyValidation(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  statusChangeAlertdata(message: any) {
    Swal.fire({
      icon: 'success',
      title: message,
      text: "Has Been Sucesfully You Will Recieve Message Regarding This Course.",
      showConfirmButton: false,
      timer: 3000,
    })
  }

  statusChangeAlert(message: any) {
    Swal.fire({
      icon: 'error',
      // text: message,
      title: message,
      showConfirmButton: false,
      timer: 3000,
    })
  }

}
