import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BannerService } from '../../banner.service';

@Component({
  selector: 'app-products-details',
  templateUrl: './products-details.component.html',
  styleUrls: ['./products-details.component.scss']
})
export class ProductsDetailsComponent implements OnInit {
  id: any;
  modalCtrl: any;
  page: number = 1;
  galleryddetails: any;
  constructor(private router: ActivatedRoute, private bannerservice: BannerService,) {
    this.id = this.router.snapshot.params.id
  }

  ngOnInit(): void {
    this.getgallery();
  }

  getgallery() {
    this.bannerservice.getgallerydata11().subscribe((res: any) => {
      console.log(res);
      this.galleryddetails = res.data.filter((data: any) => data.category_id == this.id);
      console.log(this.galleryddetails);

    })
  }

  modalDismiss() {
    this.modalCtrl.dismissAll()
  }

  showi: boolean = true;

  close() {
    this.showi = false;
  }
  modalimage1: any;
  modalimage: any = [];
  zoom(openmodal: any, image: any, img: any) {
    console.log(img);
    this.modalimage1 = img.image;
    console.log(this.modalimage1);
    this.modalimage = image[0].image;
    console.log(this.modalimage);
    this.showi = true;
    this.modalCtrl.open(openmodal, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'l' })
  }
}
