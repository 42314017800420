<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner inner-bg6">
    <div class="container">
        <div class="inner-title text-center">
            <h3>About Us</h3>
            <ul>
                <li><a href="/">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>About Us</li>
            </ul>
        </div>
    </div>
</div>

<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-content">
                    <div class="section-title">
                        <span>Get know</span>
                        <h2>A Awesome History Behind Our Success</h2>
                        <p>In Rajahmundry, Jasmine's Beauty Saloon was established in 2002 by Dr. Chandana Murali. In
                            1996, she completed her diploma. Since then, her passion for technology and fashion has
                            grown, and She laid the seed of Jasmine's Beauty Parlour with this enthusiasm.</p>

                        <p> In her 25 years of experience, She hired and educated more than a thousand beauticians,
                            placing
                            them in prestigious institutions. The scope of this beauty clinic has grown from its humble
                            beginnings to include all advanced technologies while maintaining high standards across the
                            world.</p>
                        <p>Both a United Nations University and an American university awarded her an honorary
                            doctorate.</p>
                        <p>She obtained her P.G. in Beauty Cosmetologist Certification from CISDESCO (Switzerland
                            Affiliated), P.G. Aroma Certification from CISDESCO, P.G. Hairdresser, Professional - Media
                            Makeup Laser Aesthetic (Spectronics-Dubai), and Permanent Makeup Certification from
                            CISDESCO.</p>
                    </div>

                    <div class="about-list">
                        <ul>
                            <li><i class="flaticon-curve-arrow"></i> First Be_u_ty School in Rajahmundry</li>
                            <!-- <li><i class="flaticon-curve-arrow"></i> His no legere feugaitoer in laudem</li> -->
                            <!-- <li><i class="flaticon-curve-arrow"></i> Temporeum dicant partem scripserit, doctus</li> -->
                        </ul>
                    </div>
                    <!-- <a routerLink="/team" class="default-btn border-radius">Our Courses <i class='bx bx-plus'></i></a> -->
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-img">
                    <img src="assets/img/jasmine/aboutpg.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="choose-area">
    <div class="container">
        <div class="section-title text-center">
            <span>Choose Reasons</span>
            <h2>Why Should Choose Us</h2>
        </div>

        <div class="choose-width pt-100 pb-70">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="choose-card">
                        <i class="flaticon-phone-call"></i>
                        <h3>Mobile Friendly</h3>
                        <p>Lorem ipsum dolor sit amet consecte tempo quaerat voluptatem.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="choose-card">
                        <i class="flaticon-web-page"></i>
                        <h3>Admin Panel</h3>
                        <p>Lorem ipsum dolor sit amet consecte tempo quaerat voluptatem.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                    <div class="choose-card">
                        <i class="flaticon-support"></i>
                        <h3>24 Hours Support</h3>
                        <p>Lorem ipsum dolor sit amet consecte tempo quaerat voluptatem.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="video-area video-area-bg">
    <div class="container">
        <div class="video-content">
            <h2>Are You Ready To Start Your Journey?</h2>
            <a href="https://www.youtube.com/watch?v=p7EgPxpAUHk" class="play-btn"><i class='bx bx-play'></i></a>
        </div>
    </div>
</div>

<!-- <div class="counter-area">
    <div class="container">
        <div class="counter-bg">
            <div class="row">
                <div class="col-lg-3 col-sm-6 col-md-3">
                    <div class="single-counter">
                        <h3>1254</h3>
                        <span>New Visiters Every Week</span>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-3">
                    <div class="single-counter">
                        <h3>23165</h3>
                        <span>New Visiters Every Week</span>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-3">
                    <div class="single-counter">
                        <h3>4563</h3>
                        <span>Won Amazing Awards</span>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-3">
                    <div class="single-counter">
                        <h3>880</h3>
                        <span>New Listing Every Week</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<section class="process-area">
    <div class="process-into process-into-2  pt-100 pb-70">
        <div class="container">
            <div class="section-title text-center">
                <span>Process</span>
                <h2>See <b>How to Join Us</b></h2>
                <p>Porem ipsum dolor sit ame consectetur adipisicing incididunt </p>
            </div>

            <div class="row pt-45">
                <div class="col-lg-4 col-sm-6">
                    <div class="process-item">
                        <div class="process-item-number number1">1</div>
                        <i class="flaticon-position"></i>
                        <div class="content">
                            <h3>Contact us</h3>
                            <p>Contact us and get solution to your queries. </p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="process-item">
                        <div class="process-item-number number2 active">2</div>
                        <i class="flaticon-to-do-list"></i>
                        <div class="content">
                            <h3>Register</h3>
                            <p>Enroll yourself for the Advanced beauty course. </p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                    <div class="process-item">
                        <div class="process-item-number number3">3</div>
                        <i class="flaticon-box"></i>
                        <div class="content">
                            <h3>Be a Certified beautician</h3>
                            <p>Acquire knowledge by completing this course and become a certified beautician.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="process-line-2"><img src="assets/img/shape/process-line2.png" alt="Images"></div>
    </div>
</section>

<div class="team-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Team</span>
            <h2>Our Skilled Team</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <a><img src="assets/img/jasmine/aboutdown.png" alt="Team Images"></a>
                    <div class="content">
                        <h3><a>Austin Evon</a></h3>
                        <span>Co-manager associated</span>
                        <p>Lorem ipsum dolor sit ame consectur adipisicingsedo eiusmod tempor</p>
                        <!-- <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                        </ul> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <a><img src="assets/img/jasmine/aboutdown.png" alt="Team Images"></a>
                    <div class="content">
                        <h3><a>Taylor Anderson</a></h3>
                        <span>Business consultant</span>
                        <p>Lorem ipsum dolor sit ame consectur adipisicingsedo eiusmod tempor</p>
                        <!-- <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                        </ul> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="team-card">
                    <a><img src="assets/img/jasmine/aboutdown.png" alt="Team Images"></a>
                    <div class="content">
                        <h3><a>Jorgia Klev</a></h3>
                        <span>Business Manger</span>
                        <p>Lorem ipsum dolor sit ame consectur adipisicingsedo eiusmod tempor</p>
                        <!-- <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                        </ul> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="client-area pt-100">
    <div class="container">
        <div  class="card">
            <div class="client-slider owl-carousel owl-theme">
                <div class="client-item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="client-img">
                                <img src="assets/img/jasmine/520-x456.png" alt="Images">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="client-content">
                                <h3>Oli Rubion</h3>
                                <span>Rubion Inc</span>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing sit ut fugit sed quia
                                    consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="client-item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="client-img">
                                <img src="assets/img/jasmine/520-x456.png" alt="Images">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="client-content">
                                <h3>Sanaik Tubi</h3>
                                <span>Arbon Restaurant</span>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing sit ut fugit sed quia
                                    consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="client-item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="client-img">
                                <img src="assets/img/jasmine/520-x456.png" alt="Images">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="client-content">
                                <h3>Mashrof Ruin</h3>
                                <span>Pice Cafe</span>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing sit ut fugit sed quia
                                    consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="application-area-two">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7">
                <div class="application-content">
                    <div class="section-title">
                        <span>Jasmine be_u_ty school</span>
                        <h2>Get More In Our Application Sit Back And Enjoy</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eusmod tempor incididunt ut
                            labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. </p>
                    </div>

                    <!-- <div class="application-btn">
                        <a href="#" class="application-play-btn">
                            <img src="assets/img/google-play.svg" alt="svg">
                            <div class="btn-content">
                                <span>ANDROID APP ON</span>
                                <h3>Google Play</h3>
                            </div>
                        </a>
                        <a href="#" class="application-play-btn">
                            <img src="assets/img/company.svg" alt="svg">
                            <div class="btn-content">
                                <span>DOWNLOAD ON THE</span>
                                <h3>App Store</h3>
                            </div>
                        </a>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-5">
                <div class="application-img-two">
                    <img src="assets/img/jasmine/aboutdown2.jpg" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>