<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner inner-bg1">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Salon Services</h3>
            <ul>
                <li><a href="/">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Salon Services</li>
            </ul>
        </div>
    </div>
</div>

<div class="blog-area pt-100 pb-70">
    <div class="container">
        <!-- <div class="section-title text-center">
            <span>Gallery</span>
            <h2>Latest Images <b>Post</b></h2>
        </div> -->
        <div class="row pt-45">
            <div class="col-12">
                <div class="blog-card">
                    <img src="assets/img/jasmine/salon.png" alt="Images">

                </div>
            </div>

        </div>
        <div class="row pt-45">
            <div class="col-5">
                <div style="overflow:auto; height: 400px;">
                    <table class="table table-striped" style="border: 2px solid rgb(227, 168, 255);">
                        <thead
                            style="font-size: 15px; font-weight: bold;background-color: rgb(227, 168, 255);color: white !important;top: 0px; z-index: 100;">
                            <tr style="position: sticky;">
                                <th>S.No</th>
                                <th>Salon Services</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let item of Saloondata;let i = index;">
                                <td>
                                    {{i+1}}
                                </td>
                                <td>{{item.name}}</td>
                            </tr>

                        </tbody>

                    </table>
                </div>
            </div>

            <div class="col-7">
                <div>

                    <div>
                        <h5>Salon Services Bookings</h5>
                        <!-- <button type="button" class="close" aria-hidden="true" (click)="closr()">×</button> -->
                    </div>
                    <div>

                        <form (ngSubmit)="submitservices()" [formGroup]="serviceForm">

                            <div class="row">
                                <select class="s" style="
                                        width: 100%;
                                        height: 38px;
                                        padding: 10px;
                                        background: #0065b314;
                                        color: #5773a1;
                                        font-size: 16px;
                                        border: none;
                                        margin-bottom: 20px;" formControlName="course_name" required>
                                    <option disabled>Course Name</option>
                                    <option *ngFor=" let u of Saloondata" value="{{u.id}},{{u.name}}">
                                        {{u.name}}
                                    </option>
                                </select>

                                <div class="col-xl-12">
                                    <label class="form-label">Name</label>
                                    <input type="text" class="form-control" formControlName="name" />
                                </div>
                                <div class="col-xl-12">
                                    <label class="form-label">Phone Number</label>
                                    <input type="text" class="form-control" formControlName="phonenumber"
                                        (keypress)="numberOnlyValidation($event)"
                                        onkeyup="this.value = this.value.replace(/[^a-zA-Z0-9]/g, '')" minlength="10"
                                        maxlength="10" />
                                </div>
                                <div class="col-xl-12">
                                    <label class="form-label">Email</label>
                                    <input type="text" class="form-control" formControlName="email"
                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" />
                                </div>
                                <div class="col-xl-12">
                                    <label class="form-label">Adress</label>
                                    <input type="text" class="form-control" formControlName="reg_address" />
                                </div>
                                <div class="col-xl-12">
                                    <label class="form-label"></label>
                                </div>
                                <div class="page-title-right" style="text-align: center ; ">
                                    <button class="button-customize"
                                        style="background-color: #f175cd;width: 100px;height:40px ">submit</button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer-style-two></app-footer-style-two>