import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BannerService } from '../../banner.service';

@Component({
  selector: 'app-footer-style-two',
  templateUrl: './footer-style-two.component.html',
  styleUrls: ['./footer-style-two.component.scss']
})
export class FooterStyleTwoComponent implements OnInit {


  constructor(private service:BannerService) {
  }


  ngOnInit(): void {
   
  }






}
