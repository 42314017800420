<app-navbar-style-two></app-navbar-style-two>



<owl-carousel-o [options]="bannerOptions">
    <ng-container *ngFor="let slide of banners">
        <ng-template carouselSlide>

            <div class="slider-item" [style.background]="'url(' + slide.image + ')'" style="width: 100%; height: 100%;">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="slider-content">

                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ng-container>
</owl-carousel-o>






<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="row align-items-center">
                <div class="col-lg-8">
                    <div class="section-title" style="text-align: center;">
                        <!-- <span></span> -->
                        <h2>ABTC Affiliation</h2>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="city-btn">
                        <html>

                        <head>

                            <style>
                                .blink {
                                    animation: blinker 3.2s linear infinite;
                                    color: rgb(0, 26, 255);
                                    font-family: sans-serif;
                                    text-align: center;
                                    font-size: 22px;
                                }

                                @keyframes blinker {
                                    50% {
                                        opacity: 0;
                                    }
                                }
                            </style>
                        </head>

                        <body>
                            <p class="blink">
                                <a (click)="open(openmodel)" class="logo"> <span> Click Here To Register </span><img
                                        src="assets/img/jasmine/abtc.png" alt="Logo"></a>
                            </p>
                        </body>

                        </html>

                        <!-- <a routerLink="/courses" class="default-btn">ABTC <i class='bx bx-plus'></i></a> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="about-content">
                    <div class="section-title">
                        <h2>ABTC India Aim & Agenda.</h2>
                        <p style="text-align: justify;">Our mission is to provide the advanced training you need and to
                            develop your competitive
                            edge to succeed within today’s aesthetic industry. We are constantly updating our programs
                            to include the latest, most in-demand services and industry advancements, so that our
                            graduates are always ahead of the competition. This enables our graduates to work
                            independently in their own business or in some of the most prestigious beauty spas in India
                            & abroad.
                        </p>

                        <ul>
                            <li> The primary aim was, to form a section, registered in India.</li>
                            <li> To be affiliated with CIDESCO International body.</li>
                            <li> Bring all the beauty professionals together on a common platform.</li>
                            <li> To impart knowledge and to educate them to an international level.</li>
                            <li> To groom aspiring beauty therapists to achieve their goals & make them independent
                                professionals.</li>
                            <li> To promote ABTC certification for beauty therapists who successfully pass the
                                examination.</li>
                            <li> Recognition & acceptance of ABTC beyond the boundaries of Maharashtra.</li>
                            <li> Promoting ABTC & CIDESCO schools in India.</li>
                            <li> Encouraging corporates to be a part of the ABTC.</li>
                        </ul>


                        <!-- <a routerLink="/team" class="default-btn border-radius">Our Courses <i class='bx bx-plus'></i></a> -->
                    </div> 
                </div>
            </div>
            <div class="col-lg-6">
                <div class="about-img">
                    <img src="assets/img/jasmine/maya.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>







<section class="city-area-two" style=" background-color:#dba8bb;">
    <br>
    <div class="container">

        <div class="row align-items-center">
            <div class="col-lg-8">
                <div class="section-title" style="text-align: center;">
                    <!-- <span></span> -->
                    <h2>See How It Beauty Works</h2>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="city-btn">
                    <a routerLink="/blog" class="default-btn">View Blog <i class='bx bx-plus'></i></a>
                </div>
            </div>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6" *ngFor="let item of blogs | slice:0:3">
                <div class="city-card">
                    <a routerLink="/blog">
                        <img src="{{item.image}}" alt="Images">
                    </a>
                    <div class="content">
                        <a routerLink="/blog">
                            <h3>{{item.name}}</h3>
                        </a>
                        <!-- <span>17 Locations</span> -->
                    </div>
                </div>
            </div>

            <!-- <div class="col-lg-4 col-md-6">
                <div class="city-card">
                    <a routerLink="/"><img src="assets/img/jasmine/hh2.jpg" alt="Images"></a>
                    <div class="content">
                        <a routerLink="/">
                            <h3>Boby Polishing </h3>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="city-card">
                    <a routerLink="/"><img src="assets/img/jasmine/b3.jpg" alt="Images"></a>
                    <div class="content">
                        <a routerLink="/">
                            <h3>Bridal Makeup</h3>
                        </a>
                    </div>
                </div>
            </div> -->

            <!-- <div class="col-lg-7 col-md-6">
                <div class="city-card">
                    <a routerLink="/"><img src="assets/img/city-area/city-area8.jpg" alt="Images"></a>
                    <div class="content">
                        <a routerLink="/">
                            <h3>Boby Polishing </h3>
                        </a>
                        <span>30 Locations</span>
                    </div>
                </div>
            </div>

           <div class="col-lg-5 col-md-6 offset-md-3 offset-lg-0">
                <div class="city-card">
                    <a routerLink="/"><img src="assets/img/city-area/city-area9.jpg" alt="Images"></a>
                    <div class="content">
                        <a routerLink="/">
                            <h3>Bridal Makeup</h3>
                        </a>
                        <span>34 Locations</span
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</section>

<section class="process-area process-bg2">

    <button type="submit" class="default-btn">The Visitors To The Website
        is : {{visitcount}}</button>
    <div class="process-into pt-100 pb-70">
        <div class="container">
            <div class="section-title text-center">
                <span>Process</span>
                <h2>See <b>How to Join us</b></h2>
                <p>Porem ipsum dolor sit ame consectetur adipisicing incididunt </p>
            </div>

            <div class="row pt-45">
                <div class="col-lg-4 col-sm-6">
                    <div class="process-item">
                        <div class="process-item-number number1">1</div>
                        <i class="flaticon-position"></i>
                        <div class="content">
                            <h3>Contact us</h3>
                            <p>Contact us and get solution to your queries
                                .</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="process-item">
                        <div class="process-item-number number2 active">2</div>
                        <i class="flaticon-to-do-list"></i>
                        <div class="content">
                            <h3>Register </h3>
                            <p>Enroll yourself for the Advanced beauty course.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                    <div class="process-item">
                        <div class="process-item-number number3">3</div>
                        <i class="flaticon-box"></i>
                        <div class="content">
                            <h3>Be a Certified beautician</h3>
                            <p>Acquire knowledge by completing this course and become a certified beautician.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="process-line-2"><img src="assets/img/shape/process-line2.png" alt="Images"></div>
    </div>
</section>

<br><br>
<section class="place-list-three ">
    <div class="container">
        <div class="section-title">
            <h2>Latest <b>Listings Added</b></h2>
        </div>
    </div>
    <div class="container-fluid">

        <owl-carousel-o [options]="customOptions">
            <ng-container *ngFor="let slide of updates">
                <ng-template carouselSlide>
                    <div class="place-list-item">
                        <div class="images">
                            <img [src]="slide.image" [alt]="slide.alt" id="owl-slide-1"
                                style=" width: 400px; height: 300px;">
                            <!-- <div class="place-tag">
                                <ul>
                                    <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                                    <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                                    <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                                </ul>
                            </div> -->
                        </div>

                        <div class="content" style=" width: 400px; height:230px;">
                            <a routerLink="/">
                                <h3>{{slide.name}}</h3>
                            </a>
                            <p><i class="flaticon-cursor"></i>Rajahmundry</p>
                            <p style="overflow-y:auto; height:100px;padding: 10px; text-align: justify;"><a
                                    routerLink="/">{{slide.description}}</a></p>
                        </div>
                    </div>
                </ng-template>
            </ng-container>
        </owl-carousel-o>



















        <!-- <div class="place-list-item">
                <div class="images">
                    <a routerLink="/" class="images-list"><img src="assets/img/jasmine/b2.jpg" alt="Images"></a>

                    <div class="place-tag">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                        </ul>
                    </div>
                </div>

                <div class="content">
                    <a routerLink="/">
                        <h3>The Body Polishing Treatment</h3>
                    </a>
                    <p><i class="flaticon-cursor"></i> Rajahmundry</p>
                    <ul class="place-rating">
                        <li><a routerLink="/">5.0</a></li>
                        <li>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="place-list-item">
                <div class="images">
                    <a routerLink="/" class="images-list"><img src="assets/img/jasmine/b3.jpg" alt="Images"></a>
                  
                    <div class="place-tag">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                        </ul>
                      
                    </div>
                </div>
                <div class="content">
                    <a routerLink="/">
                        <h3>Bridal Makeup</h3>
                    </a>
                    <p><i class="flaticon-cursor"></i>Rajahmundry</p>
                    <ul class="place-rating">
                        <li><a routerLink="/">4.5</a></li>
                        <li>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star icon-color'></i>
                            <i class='bx bxs-star icon-color'></i>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="place-list-item">
                <div class="images">
                    <a routerLink="/" class="images-list"><img src="assets/img/jasmine/b4.jpg" alt="Images"></a>

                    <div class="place-tag">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                        </ul>
                    </div>
                </div>

                <div class="content">
                    <a routerLink="/">
                        <h3>Hair Colouring </h3>
                    </a>
                    <p><i class="flaticon-cursor"></i> Rajahmundry</p>
                    <ul class="place-rating">
                        <li><a routerLink="/">4.7</a></li>
                        <li>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star icon-color'></i>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="place-list-item">
                <div class="images">
                    <a routerLink="/" class="images-list"><img src="assets/img/jasmine/b5.jpg" alt="Images"></a>

               

                    <div class="place-tag">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                        </ul>
                      
                    </div>
                </div>

                <div class="content">
                    <a routerLink="/">
                        <h3>Japanese Techology Facial </h3>
                    </a>
                    <p><i class="flaticon-cursor"></i>Rajahmundry</p>
                    <ul class="place-rating">
                        <li><a routerLink="/">4.9</a></li>
                        <li>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star icon-color'></i>
                            <i class='bx bxs-star icon-color'></i>
                        </li>
                    </ul>
                </div> 
            </div>-->
        <!-- </div> -->
    </div>
</section>

<div class="video-area-two">
    <div class="container">
        <div class="video-option">
            <a href="https://www.youtube.com/watch?v=p7EgPxpAUHk" class="video-btn">
                <i class='bx bx-play play-video'></i>
                <span><i class='flaticon-arrow'></i> Play Video</span>
            </a>
        </div>
    </div>
</div>

<!-- <div class="counter-area-three">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="single-counter">
                    <h3 class="text-color-white">105</h3>
                    <span>New Visiters Every Week</span>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="single-counter">
                    <h3 class="text-color-white">8510</h3>
                    <span>New Visiters Every month</span>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="single-counter">
                    <h3 class="text-color-white">12</h3>
                    <span>Won Amazing Awards</span>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="single-counter">
                    <h3 class="text-color-white">900</h3>
                    <span>New Listing Every Week</span>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- <div class="place-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-6">
                <div class="section-title mb-45">
                    <span>Desire Places</span>
                    <h2>Most <b>Popular Places</b></h2>
                </div>
            </div>

            <div class="col-lg-5 col-md-6">
                <div class="place-btn">
                    <a routerLink="/listing" class="default-btn">Check out all places <i class='bx bx-plus'></i></a>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="place-card">
                    <a routerLink="/" class="place-images"><img
                            src="assets/img/place-area/place-area1.jpg" alt="Images"></a>

                    <div class="rating">
                        <ul>
                            <li><a routerLink="/">4.9</a></li>
                            <li>
                                <span>23 Review</span>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star i-color'></i>
                                <i class='bx bxs-star i-color'></i>
                            </li>
                        </ul>
                    </div>

                    <div class="status-tag bg-dark-orange">
                        <a routerLink="/">
                            <h3>Closed Now</h3>
                        </a>
                    </div>

                    <div class="content">
                        <div class="content-profile">
                            <img src="assets/img/place-area/place-area-profile.png" alt="Images">
                            <h3>By, Lowis Jelda</h3>
                        </div>
                        <span><i class="flaticon-cursor"></i> Dongo 184 Crono, Canada</span>
                        <a routerLink="/">
                            <h3>Denisto Centin Restaurant </h3>
                        </a>
                        <p>Lorem ipsum dolor sit amet, consectetur quam quaerat voluptatem.</p>
                        <div class="content-tag">
                            <ul>
                                <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                            </ul>
                            <h3 class="price"> <a routerLink="/">$560-890</a></h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="place-card">
                    <a routerLink="/" class="place-images"><img
                            src="assets/img/place-area/place-area2.jpg" alt="Images"></a>

                    <div class="rating">
                        <ul>
                            <li><a routerLink="/">5.0</a></li>
                            <li>
                                <span>30 Review</span>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </li>
                        </ul>
                    </div>

                    <div class="status-tag bg-color-blue">
                        <a routerLink="/">
                            <h3>Open Now</h3>
                        </a>
                    </div>

                    <div class="content">
                        <div class="content-profile">
                            <img src="assets/img/place-area/place-area-profile2.png" alt="Images">
                            <h3>By, Austin Deli</h3>
                        </div>
                        <span><i class="flaticon-cursor"></i> 40 Square Plaza, NJ, USA</span>
                        <a routerLink="/">
                            <h3>Iconic Cafe in Ontario</h3>
                        </a>
                        <p>Lorem ipsum dolor sit amet, consectetur quam quaerat voluptatem.</p>
                        <div class="content-tag">
                            <ul>
                                <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                            </ul>
                            <h3 class="price"><a routerLink="/">$500-700</a></h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="place-card">
                    <a routerLink="/" class="place-images"><img
                            src="assets/img/place-area/place-area3.jpg" alt="Images"></a>

                    <div class="rating">
                        <ul>
                            <li><a routerLink="/">4.9</a></li>
                            <li>
                                <span>23 Review</span>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star i-color'></i>
                                <i class='bx bxs-star i-color'></i>
                            </li>
                        </ul>
                    </div>

                    <div class="status-tag bg-color-heliotrope">
                        <a routerLink="/">
                            <h3>Open Now</h3>
                        </a>
                    </div>

                    <div class="content">
                        <div class="content-profile">
                            <img src="assets/img/place-area/place-area-profile3.png" alt="Images">
                            <h3>By, Polin Osto</h3>
                        </div>
                        <span><i class="flaticon-cursor"></i> 34-42 Montgomery St , NY, USA</span>
                        <a routerLink="/">
                            <h3>Strong body Gym</h3>
                        </a>
                        <p>Lorem ipsum dolor sit amet, consectetur quam quaerat voluptatem.</p>
                        <div class="content-tag">
                            <ul>
                                <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                            </ul>
                            <h3 class="price"><a routerLink="/">$400-800</a></h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="place-card">
                    <a routerLink="/" class="place-images"><img
                            src="assets/img/place-area/place-area4.jpg" alt="Images"></a>

                    <div class="rating">
                        <ul>
                            <li><a routerLink="/">4.8</a></li>
                            <li>
                                <span>16 Review</span>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star i-color'></i>
                                <i class='bx bxs-star i-color'></i>
                                <i class='bx bxs-star i-color'></i>
                            </li>
                        </ul>
                    </div>

                    <div class="status-tag bg-dark-orange">
                        <a routerLink="/">
                            <h3>Closed Now</h3>
                        </a>
                    </div>

                    <div class="content">
                        <div class="content-profile">
                            <img src="assets/img/place-area/place-area-profile4.png" alt="Images">
                            <h3>By, Debit Jhon</h3>
                        </div>
                        <span><i class="flaticon-cursor"></i> 27th Brooklyn New York, USA</span>
                        <a routerLink="/">
                            <h3>Family Convenience Store</h3>
                        </a>
                        <p>Lorem ipsum dolor sit amet, consectetur quam quaerat voluptatem.</p>
                        <div class="content-tag">
                            <ul>
                                <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                            </ul>
                            <h3 class="price"><a routerLink="/">$560-890</a></h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="place-card">
                    <a routerLink="/" class="place-images"><img
                            src="assets/img/place-area/place-area5.jpg" alt="Images"></a>

                    <div class="rating">
                        <ul>
                            <li><a routerLink="/">5.0</a></li>
                            <li>
                                <span>16 Review</span>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </li>
                        </ul>
                    </div>

                    <div class="status-tag bg-color-blue">
                        <a routerLink="/">
                            <h3>Closed Now</h3>
                        </a>
                    </div>

                    <div class="content">
                        <div class="content-profile">
                            <img src="assets/img/place-area/place-area-profile5.png" alt="Images">
                            <h3>By, Kelvin Sasi</h3>
                        </div>
                        <span><i class="flaticon-cursor"></i> 56 Street Square Plaza, NJ, USA</span>
                        <a routerLink="/">
                            <h3>Iconic Cafe in Onterio</h3>
                        </a>
                        <p>Lorem ipsum dolor sit amet, consectetur quam quaerat voluptatem.</p>
                        <div class="content-tag">
                            <ul>
                                <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                            </ul>
                            <h3 class="price"><a routerLink="/">$300-600</a></h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="place-card">
                    <a routerLink="/" class="place-images"><img
                            src="assets/img/place-area/place-area6.jpg" alt="Images"></a>

                    <div class="rating">
                        <ul>
                            <li><a routerLink="/">5.0</a></li>
                            <li>
                                <span>39 Review</span>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </li>
                        </ul>
                    </div>

                    <div class="status-tag bg-color-green">
                        <a routerLink="/">
                            <h3>Open Now</h3>
                        </a>
                    </div>

                    <div class="content">
                        <div class="content-profile">
                            <img src="assets/img/place-area/place-area-profile6.png" alt="Images">
                            <h3>By, Creiun Hitler</h3>
                        </div>
                        <span><i class="flaticon-cursor"></i> 34-42 Montgomery St , NY, USA</span>
                        <a routerLink="/">
                            <h3>Kentorin Hotel</h3>
                        </a>
                        <p>Lorem ipsum dolor sit amet, consectetur quam quaerat voluptatem.</p>
                        <div class="content-tag">
                            <ul>
                                <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                            </ul>
                            <h3 class="price"><a routerLink="/">$400-800</a></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="client-area pt-100">
    <div class="container">
        <div class="card">
            <!-- <ngx-slick-carousel class="blog_slide_v1" #slickModal="slick-carousel" [config]="settings">
                <div ngxSlickItem class="ovent-img" class="blog-item">
                    <div class="client-item" *ngFor="let item of testimonials">
                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                <img [src]="item.image" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen class="img-fluid" style="width: 550px; height: 330px;">
                            </div>
                            <div class="col-lg-6">
                                <div class="client-content">

                                    <h3>{{item.name}}</h3>
                                    <p>{{item.description}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ngx-slick-carousel> -->



            <owl-carousel-o [options]="testimonialOptions">
                <ng-container *ngFor="let slide of testimonials">
                    <ng-template carouselSlide>
                        <div class="client-item">
                            <div class="row align-items-center">
                                <div class="col-lg-6">
                                    <div class="client-img">
                                        <img src="{{slide.image}}" alt="Images" style="width: 100%; height: 387px;">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="client-content">
                                        <h3>{{slide.name}}</h3>
                                        <p>{{slide.description}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>


            <!-- <div class="client-item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="client-img">
                                <img src="assets/img/jasmine/520-x456.png" alt="Images">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="client-content">
                                <h3>Sailu</h3>
                                <p>Excellent CIDESO Certified beauty school in Rajahmundry mywife trained here and
                                    gained indepth knowlege in Cosmetology and now my wife runs her own parlour .</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="client-item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="client-img">
                                <img src="assets/img/jasmine/520-x456.png" alt="Images">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="client-content">
                                <h3>Teja Swini</h3>
                                <p>Wonderful experience.Excellent serviceThis is the only parlour I trust in
                                    Rajahmundry.The staff is well trained and well mannered. Mam is very friendly and
                                    knowledgeable .</p>
                            </div>
                        </div>
                    </div>
                </div> -->
            <!-- </div> -->
        </div>
    </div>
</div>

<!-- <div class="application-area-two">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7">
                <div class="application-content">
                    <div class="section-title">
                        <span>Download app</span>
                        <h2>Get More In Our Application Sit Back And Enjoy</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eusmod tempor incididunt ut
                            labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. </p>
                    </div>
                    <div class="application-btn">
                        <a href="#" class="application-play-btn">
                            <img src="assets/img/google-play.svg" alt="svg">
                            <div class="btn-content">
                                <span>ANDROID APP ON</span>
                                <h3>Google Play</h3>
                            </div>
                        </a>
                        <a href="#" class="application-play-btn">
                            <img src="assets/img/company.svg" alt="svg">
                            <div class="btn-content">
                                <span>DOWNLOAD ON THE</span>
                                <h3>App Store</h3>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-5">
                <div class="application-img-two">
                    <img src="assets/img/mobile2.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div> -->
<ng-template #openmodel let-modal>
    <div>
        <div class="modal-header">
            <h5 class="modal-title mt-0 ">Course Registration </h5>
            <button type="button" class="close" aria-hidden="true" (click)="closr()">×</button>
        </div>
        <div class="modal-body">
            <form (ngSubmit)="submitservices()" [formGroup]="serviceForm">
                <div class="col-xl-12">
                    <div class="row">
                        <div class="col-xl-12">
                            <label class="form-label">Course Name<span style="color:red">*</span></label>

                            <select class="form_group" style="
                    width: 100%;
                    height: 38px;
                    padding: 10px;
                    background: #0065b314;
                    color: #5773a1;
                    font-size: 16px;
                    border: none;
                    margin-bottom: 20px;" class="form-select" name="account" formControlName="course_name"
                                aria-label="Default select example" required>
                                <option selected value="" disabled>Course Name</option>
                                <option *ngFor=" let u of coursesdata" value="{{u.id}},{{u.name}}">{{u.name}}
                                </option>
                            </select>
                        </div>
                        <div class="col-xl-12">
                            <label class="form-label">Name</label>
                            <input type="text" class="form-control" formControlName="name" />
                        </div>
                        <div class="col-xl-12">
                            <label class="form-label">Phone Number</label>
                            <input type="text" class="form-control" formControlName="phonenumber"
                                (keypress)="numberOnlyValidation($event)"
                                onkeyup="this.value = this.value.replace(/[^a-zA-Z0-9]/g, '')" minlength="10"
                                maxlength="10" />
                        </div>
                        <div class="col-xl-12">
                            <label class="form-label">Email</label>
                            <input type="text" class="form-control" formControlName="email" />
                        </div>
                        <div class="col-xl-12">
                            <label class="form-label">Adress</label>
                            <input type="text" class="form-control" formControlName="reg_address" />
                        </div>
                        <div class="col-xl-12">
                            <label class="form-label"></label>
                        </div>
                        <div class="page-title-right" style="text-align: center ; ">
                            <button class="button-customize"
                                style="background-color: #f175cd;width: 100px;height:40px ">submit</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>
<app-footer-style-two></app-footer-style-two>