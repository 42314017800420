import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BannerService } from '../../banner.service';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// import {/
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  jasminesotherForm: FormGroup;
  jasminesstaffForm: FormGroup;
  serviceForm: FormGroup;
  constructor(private formBuilder: FormBuilder, private bannerservice: BannerService, private modal: NgbModal,) {
    this.serviceForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      email: ["", [Validators.required]],
      phone_number: ["", [Validators.required]],
      msg_subject: ["", [Validators.required]],
      message: ["", [Validators.required]],
    })

    this.jasminesstaffForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      email: ["", [Validators.required]],
      phonenumber: ["", [Validators.required]],
      gender: ["", [Validators.required]],
      qualification: ["", [Validators.required]],
      reg_address: ["", [Validators.required]],
    })

    this.jasminesotherForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      email: ["", [Validators.required]],
      phonenumber: ["", [Validators.required]],
      shopname: ["", [Validators.required]],
      msg_description: ["", [Validators.required]],
      reg_address: ["", [Validators.required]],
    })
  }
  ngOnInit(): void {
  }
  submitservices() {
    if (this.serviceForm.invalid) {
      this.statusChangeAlert("Please Fill All The Details")
      return;
    } else {
      console.log(this.serviceForm.value)
      this.bannerservice.postcontactdata(this.serviceForm.value).subscribe(
        (res) => {
          this.statusChangeAlertdata("We Will Contact You Soon");
          this.serviceForm.reset();
        }, error => {
        })
    }
  }


  submitjasminesdata() {
    console.log(this.jasminesstaffForm.value);
    if (this.jasminesstaffForm.invalid) {
      this.statusChangeAlert("Please Fill All The Details")
      return;
    } else {
      console.log(this.jasminesstaffForm.value)
      this.bannerservice.postjasminesstaffFormdata(this.jasminesstaffForm.value).subscribe(
        (res) => {
          this.statusChangeAlertdata("We Will Contact You Soon");
          this.jasminesstaffForm.reset();
        }, error => {
        })
    }

  }

  submitotherjasminesdata() {
    console.log(this.jasminesotherForm.value);
    if (this.jasminesotherForm.invalid) {
      this.statusChangeAlert("Please Fill All The Details")
      return;
    } else {
      console.log(this.jasminesotherForm.value)
      this.bannerservice.postjasminesotherdata(this.jasminesotherForm.value).subscribe(
        (res) => {
          this.statusChangeAlertdata("We Will Contact You Soon");
          this.jasminesotherForm.reset();
        }, error => {
        })
    }

  }


  numberOnlyValidation(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  statusChangeAlertdata(message: any) {
    Swal.fire({
      icon: 'success',
      // text: message,
      title: message,
      showConfirmButton: false,
      timer: 3000,
    })
  }

  statusChangeAlert(message: any) {
    Swal.fire({
      icon: 'error',
      // text: message,
      title: message,
      showConfirmButton: false,
      timer: 3000,
    })
  }


  open(openjobmodel) {
    this.modal.open(openjobmodel, { centered: true, size: 'l' })
  }

  openfor(openmodelforother) {
    this.modal.open(openmodelforother, { centered: true, size: 'l' })
  }

  closr() {
    this.modal.dismissAll();
    this.jasminesstaffForm.reset();

  }


  closed() {
    this.modal.dismissAll();
    this.jasminesotherForm.reset();

  }
}
