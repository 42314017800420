<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner inner-bg8">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Courses</h3>
            <ul>
                <li><a href="/">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Courses</li>
            </ul>
        </div>
    </div>
</div>
<div class="team-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Courses</span>
            <h2>Our Courses</h2>
        </div>


        <div class="side-nav d-in-line align-items-center">
            <div class="side-item">
                <div class="nav-add-btn" (click)="open(openmodel)">
                    <a class="default-btn border-radius">Course Registration <i class='bx bx-notepad'></i></a>
                </div>
            </div>
        </div>
        <div class="row pt-45 ">
            <div class="col-lg-4 col-md-6"
                *ngFor="let item of coursesdata | paginate: { itemsPerPage: 6, currentPage: page }">
                <div class="team-card">
                    <img src="{{item.image}}" alt="Team Images" style="width: 650px; height: 365px;">
                    <div class="content" style="height: 230px;">
                        <h3>{{item.name}}</h3>
                        <span>{{item.duration}}</span>
                        <p class="justify" style="overflow-y:auto; height:100px;padding: 10px;">{{item.description}}</p>
                    </div>
                </div>
            </div>
            <div class="pagination justify-content-center mt-50">
                <pagination-controls (pageChange)="page = $event" responsive="true" previousLabel="<"
                    nextLabel=">"></pagination-controls>
            </div>
        </div>
    </div>
</div>
<ng-template #openmodel let-modal>
    <div>
        <div class="modal-header">
            <h5 class="modal-title mt-0 ">Course Registration </h5>
            <button type="button" class="close" aria-hidden="true" (click)="closr()">×</button>
        </div>
        <div class="modal-body">
            <form (ngSubmit)="submitservices()" [formGroup]="serviceForm">
                <div class="col-xl-12">
                    <div class="row">
                        <div class="col-xl-12">
                            <label class="form-label">Course Name<span style="color:red">*</span></label>

                            <select class="s" style="
                    width: 100%;
                    height: 38px;
                    padding: 10px;
                    background: #0065b314;
                    color: #5773a1;
                    font-size: 16px;
                    border: none;
                    margin-bottom: 20px;" formControlName="course_name" aria-label="Default select example" required>
                                <option selected value="" disabled>Course Name</option>
                                <option *ngFor=" let u of coursesdata" value="{{u.id}},{{u.name}}">{{u.name}}
                                </option>
                            </select>
                        </div>
                        <div class="col-xl-12">
                            <label class="form-label">Name</label>
                            <input type="text" class="form-control" formControlName="name" />
                        </div>
                        <div class="col-xl-12">
                            <label class="form-label">Phone Number</label>
                            <input type="text" class="form-control" formControlName="phonenumber"
                                (keypress)="numberOnlyValidation($event)"
                                onkeyup="this.value = this.value.replace(/[^a-zA-Z0-9]/g, '')" minlength="10"
                                maxlength="10" />
                        </div>
                        <div class="col-xl-12">
                            <label class="form-label">Email</label>
                            <input type="text" class="form-control" formControlName="email" />
                        </div>
                        <div class="col-xl-12">
                            <label class="form-label">Adress</label>
                            <input type="text" class="form-control" formControlName="reg_address" />
                        </div>
                        <div class="col-xl-12">
                            <label class="form-label"></label>
                        </div>
                        <div class="page-title-right" style="text-align: center ; ">
                            <button class="button-customize"
                                style="background-color: #f175cd;width: 100px;height:40px ">submit</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>
<app-footer-style-two></app-footer-style-two>