<app-navbar-style-two></app-navbar-style-two>
<div class="inner-banner inner-bg9">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Blog</h3>
            <ul>
                <li><a href="/">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Blog-details</li>
            </ul>
        </div>
    </div>
</div>
<!-- <div class="inner-banner inner-bg4">
    <div class="container">
        <div class="inner-banner-title text-center">
            <h3>Property For Sale</h3>
            <p>News pariatur Excepteur sint occaecat iat nulla pariatur Excepteur </p>
        </div>
        
        <div class="banner-list">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-7">
                    <ul class="list">
                        <li><a href="index.html">Home</a></li>
                        <li><i class='bx bx-chevron-right'></i></li>
                        <li>Pages</li>
                        <li><i class='bx bx-chevron-right'></i></li>
                        <li class="active">Blog Details</li>
                    </ul>
                </div>

                <div class="col-lg-6 col-md-5">
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->

<section class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc" *ngFor="let item of blogs">
                    <div class="article-image">
                        <img src="{{item.image}}">
                    </div>

                    <div class="article-content">
                        <div class="entry-meta">
                            <ul>
                                <li><span>Posted By:</span> <a class="texts" style="
                                    font-weight: 500;
                                    font-family: Ramabhadra, sans-serif !important ;
                                    font-size: 24px;
                                    " routerLink="/blog">{{item.name}} </a></li>
                            </ul>
                        </div>
                        <h3 class="texts" style="
                        font-weight: 500;
                        font-family: Ramabhadra, sans-serif !important ;
                        font-size: 44px;
                        "></h3>


                        <p class="texts" style="
                            font-weight: 500;
                            font-family: Ramabhadra, sans-serif !important ;
                            font-size: 24px;
                            "> {{item.description}}</p>
                        <p class="texts" style="
                        font-weight: 500;
                        font-family: Ramabhadra, sans-serif !important ;
                        font-size: 24px;
                        "> {{item.content1}}</p>
                        <p class="texts" style="
                        font-weight: 500;
                        font-family: Ramabhadra, sans-serif !important ;
                        font-size: 24px;
                        "></p>

                        <p class="texts" style="
                        font-weight: 500;
                        font-family: Ramabhadra, sans-serif !important ;
                        font-size: 24px;
                        ">{{item.content2}}.</p>
                        <div class="row mb-3">
                            <div class="col-lg-4 col-sm-6 mt-4">
                                <div class="b-d-s-img">
                                    <img src="{{item.image2}}" alt="Images" style="height: 224px;">
                                </div>
                            </div>

                            <div class="col-lg-4 col-sm-6 mt-4">
                                <div class="b-d-s-img">
                                    <img src="{{item.image3}}" alt="Images" style="height: 224px;">
                                </div>
                            </div>

                            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0 mt-4">
                                <div class="b-d-s-img">
                                    <img src="{{item.image4}}" alt="Images" style="height: 224px;">
                                </div>
                            </div>
                        </div>
                        <p class="texts" style="
                        font-weight: 500;
                        font-family: Ramabhadra, sans-serif !important ;
                        font-size: 24px;
                        ">{{item.content3}}</p>

                    </div>

                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area" id="secondary">
                    <section class="widget widget-peru-posts-thumb">
                    </section>

                </aside>
            </div>
        </div>
    </div>
</section>

<app-footer-style-two></app-footer-style-two>